import React, { useRef } from 'react';
import type { FC } from 'react';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import Hero from './Hero';
import Features from './Features';
import Testimonials from './Testimonials'
import CTA from './CTA';
import FAQS from './FAQS';
import Footer from 'src/components/Footer';
import { MessengerChat } from "react-messenger-chat-plugin";
import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  dividerTop: {
    transform: 'rotate(180deg)',
    '& svg': {
      width: 'calc(160% + 1.3px)',
      height: '75px',
      fill: theme.palette.background.dark,
    },
    [theme.breakpoints.down('md')]: {
      '& svg': {
        width: 'calc(166% + 1.3px)',
        height: '62px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        width: 'calc(162% + 1.3px)',
        height: '35px',
      },
    },
  },
  dividerBottom: {
    backgroundColor: theme.palette.background.dark,
    transform: 'rotate(180deg)',
    '& svg': {
      width: 'calc(160% + 1.3px)',
      height: '75px',
      transform: 'rotateY(180deg)',
      fill: '#ffffff',
    },
    [theme.breakpoints.down('md')]: {
      '& svg': {
        width: 'calc(166% + 1.3px)',
        height: '62px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        width: 'calc(162% + 1.3px)',
        height: '35px',
      },
    },
  },
}));

const HomeView: FC = () => {
  const classes = useStyles();
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth' });

  (window as any).scroll = executeScroll;

  return (
    <Page
      className={classes.root}
      title="Home"
    >
      <Hero />
      <Features />
      <div className={classes.dividerTop}>
        <svg data-name="Layer 1" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" />
        </svg>
      </div>
      <Testimonials />
      <div className={classes.dividerBottom}>
        <svg data-name="Layer 1" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" />
        </svg>
      </div>
      {/* <CTA /> */}
      <div ref={myRef}>
        <FAQS />
      </div>
      <Footer execute={executeScroll} />
      <MessengerChat
        pageId="106401165686450"
        language="en_US"
        themeColor={"#22A1B6"}
        bottomSpacing={20}
        loggedInGreeting="Let's get connected!"
        loggedOutGreeting="Let's get connected!"
        greetingDialogDisplay={"show"}
        debugMode={true}
        onMessengerShow={() => {
          console.log("onMessengerShow");
        }}
        onMessengerHide={() => {
          console.log("onMessengerHide");
        }}
        onMessengerDialogShow={() => {
          console.log("onMessengerDialogShow");
        }}
        onMessengerDialogHide={() => {
          console.log("onMessengerDialogHide");
        }}
        onMessengerMounted={() => {
          console.log("onMessengerMounted");
        }}
        onMessengerLoad={() => {
          console.log("onMessengerLoad");
        }}
      />
    </Page>
  );
};


export default HomeView;
