import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  IconButton,
  Divider
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import type { Theme } from 'src/theme';
import Logo from 'src/components/Logo';
import Zoom from 'react-reveal/Zoom';
import environment from 'src/environments';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

interface FooterProps {
  className?: string;
  execute?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'white',
    paddingTop: 60,
    paddingBottom: 28
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  followUsContainer: {
    margin: 'auto',
    textAlign: 'center',
    marginTop: 32,
    marginBottom: 32,
    color: '#838383',
    [theme.breakpoints.up('md')]: {
      maxWidth: 600,
    },
  },
  iconSize: {
    fontSize: 35
  },
  learnMoreContainer: {
    margin: 'auto',
    maxWidth: 850,
    textAlign: 'center',
    marginTop: 48
  },
  learnMoreContent: {
    display: 'flex',
    justifyContent: 'center',
    rowGap: 40,
    [theme.breakpoints.up('md')]: {
      spacing: 3,
    },
  },
  companyContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: -20,
    marginBottom: 32,
    gap: 20,
    color: '#838383',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  poweredByContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    justifyContent: 'center'
  },
  copyrightContainer: {
    textAlign: 'center',
    marginTop: 18,
    [theme.breakpoints.up('md')]: {
      marginTop: 0
    },
  },
  linkStyle: {
    color: '#757575', 
    cursor: 'pointer'
  }
}));

const Footer: FC<FooterProps> = ({ className, ...rest }) => {
  const classes = useStyles();
  let faq;
  const onNavigateToFAQ = () => { window.open(`${environment.baseUrl}/faq`, '_blank').focus()};
  if(rest.execute) faq = rest.execute 
  else faq = onNavigateToFAQ;
  
  const handleClick = faq;
  
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Zoom>
        <Container maxWidth="lg">
          <Box display={'flex'} alignSelf={'center'} justifyContent={'center'}>
            <Logo style={{ height: 120 }} />
          </Box>

          <Box mt={8} className={classes.learnMoreContainer}>
            <Grid container columns={{ xs: 6, sm: 6, md: 12 }} className={classes.learnMoreContent}>
              {/* TODO: Uncomment this section if the about page is done and implemented */}
              {/* <Grid item xs={2} sm={2} md={2}>
                <Typography
                  variant="body1"
                  color="secondary"
                >
                  <Link onClick={() => window.open(`${environment.baseUrl}/#`, '_newtab')} underline="hover" className={classes.linkStyle}>About</Link>
                </Typography>
              </Grid> */}
              <Grid item xs={2} sm={2} md={2}>
                <Typography
                  variant="body1"
                  color="secondary"
                >
                  <Link onClick={() => window.open(`${environment.baseUrl}/help-center`, '_newtab')} underline="hover" className={classes.linkStyle}>Help Center</Link>
                </Typography>
              </Grid>
              <Grid item xs={1.5} sm={1.5} md={1.5}>
                <Typography
                  variant="body1"
                  color="secondary"
                >
                  <Link onClick={handleClick} underline="hover" className={classes.linkStyle}>FAQs</Link>
                </Typography>
              </Grid>
              <Grid item xs={2.5} sm={2.5} md={3}>
                <Typography
                  variant="body1"
                  color="secondary"
                >
                  <Link onClick={() => window.open(`${environment.baseUrl}/terms-of-use`, '_newtab')} underline="hover" className={classes.linkStyle}>Terms of Service</Link>
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={3}>
                <Typography
                  variant="body1"
                  color="secondary"
                >
                  <Link onClick={() => window.open(`${environment.baseUrl}/privacy-policy`, '_newtab')} underline="hover" className={classes.linkStyle}>Privacy Policy</Link>
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                <Typography
                  variant="body1"
                  color="secondary"
                >
                  <Link onClick={() => window.open('https://www.messenger.com/t/106401165686450/', '_newtab')} underline="hover" className={classes.linkStyle}>Contact Us</Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box mt={8} className={classes.followUsContainer}>
            <Grid container spacing={0} columns={12}>
              <Grid item xs={2}>
                <IconButton aria-label="facebook" component="label" onClick={() => window.open('https://web.facebook.com/paygrapp', '_newtab')}>
                  <FacebookIcon className={classes.iconSize} />
                </IconButton>
              </Grid>
              <Grid item xs={2}>
                <IconButton aria-label="instagram" component="label" onClick={() => window.open('https://www.instagram.com/paygrapp/', '_newtab')}>
                  <InstagramIcon className={classes.iconSize} />
                </IconButton>
              </Grid>
              <Grid item xs={2}>
                <IconButton aria-label="twitter" component="label" onClick={() => window.open('https://twitter.com/paygr_app', '_newtab')}>
                  <TwitterIcon className={classes.iconSize} />
                </IconButton>
              </Grid>
              <Grid item xs={2}>
                <IconButton aria-label="tiktok" component="label" onClick={() => window.open('https://www.tiktok.com/@paygr', '_newtab')}>
                  <img
                    alt="Tiktok Logo"
                    width={33}
                    src="/static/tiktok.svg"
                  />
                </IconButton>
              </Grid>
              <Grid item xs={2}>
                <IconButton aria-label="youtube" component="label" onClick={() => window.open('https://www.youtube.com/@paygrapp', '_newtab')}>
                  <YouTubeIcon className={classes.iconSize} />
                </IconButton>
              </Grid>
              <Grid item xs={2}>
                <IconButton aria-label="linkedin" component="label" onClick={() => window.open('https://www.linkedin.com/company/paygr/', '_newtab')}>
                  <LinkedInIcon className={classes.iconSize} />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box mt={8}>
            <Grid
              container
              spacing={3}
            >
              <Grid item xs={12}>
                <Box className={classes.companyContainer}>
                  <Box className={classes.poweredByContainer}>
                    <Typography
                      variant="h5"
                    >
                      Powered by <Link onClick={() => window.open('https://www.titosolutions.ph/', '_newtab')} underline="hover" className={classes.linkStyle}>Tito Solutions</Link>
                    </Typography>
                  </Box>
                  <Box className={classes.copyrightContainer}>
                    <Typography
                      variant="h5"
                    >
                      © Copyright 2023 Paygr | All Rights Reserved
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Zoom>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
