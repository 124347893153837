import React from 'react';
import type { FC } from 'react';

interface LogoProps {
  [key: string]: any;
}

const Logo: FC<LogoProps> = (props) => {

  if (props?.whiteLogo) {
    return (
      <img
        alt="Logo"
        src="/static/home/paygr_logo_text.png"
        {...props}
      />
    );
  }
  return (
    <img
      alt="Logo"
      src="/static/paygr_logo_2.png"
      {...props}
    />
  );
}

export default Logo;
