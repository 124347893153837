import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import ReactPlayer from 'react-player/lazy';
import { Theme } from 'src/theme';
interface FeatureVideoProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%'
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0
  }
}));

const FeatureVideo: FC<FeatureVideoProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={classes.playerWrapper}>
      <ReactPlayer
        url="https://www.youtube.com/watch?v=3qFyRoM3FBU"
        className={classes.reactPlayer}
        height={'100%'}
        width={'100%'}
      />
    </div>
  );
};

export default FeatureVideo;
