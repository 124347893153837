export const APP_VERSION = '1.0.2';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const BASE_URL = 'https://paygr.app';
export const ANDROID_PACKAGE_NAME = 'ph.titosolutions.paygr';
export const IOS_BUNDLE_ID = 'ph.titosolutions.paygr';
export const IOS_APPSTORE_ID = '1665015003';
