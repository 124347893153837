import React from 'react';
import { Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from 'src/theme';
import Logo from 'src/components/Logo';
import { Link } from 'react-router-dom';
import environment from 'src/environments';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

const Header = ({ whiteLogo = true }: { whiteLogo?: boolean }) => {
  const classes = useStyles();

  const onNavigateToHelpCenter = () => {
    window.open(`${environment.baseUrl}/help-center`, '_blank').focus();
  };

  return (
    <Box className={classes.header}>
      <Link to="/">
        <Logo style={{ height: 100 }} whiteLogo={whiteLogo} />
      </Link>
      <Box>
        <Button
          variant="text"
          sx={{ color: '#22A1B6' }}
          onClick={onNavigateToHelpCenter}
        >
          Help Center
        </Button>
      </Box>
    </Box>
  );
};

export default Header;
