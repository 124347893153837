import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  } from '@mui/material';
import Fade from 'react-reveal/Fade';
import Testimonies from './Testimonies';
import { makeStyles } from '@mui/styles';
import type { Theme } from 'src/theme';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';

// import required modules
import { Pagination, Navigation, Autoplay } from "swiper";
interface TestimonialsProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 64,
    paddingBottom: 64,
    color:'black'
  },
  testimony:{
    color:'#124954',
    padding:15,
    fontWeight:'bold',
    '&::before':{
      content:'open-quote',
      fontSize:'19px'
    },
    '&::after':{
      content:'close-quote',
      fontSize:'19px'
    }
  },
  avatar:{
    border: '3px solid #1B4A52',
    width:'90px',
    height:'90px',
    [theme.breakpoints.down('sm')]: {
      width:'70px',
      height:'70px',
    }
  },
  card:{
    cursor:'pointer',
    backgroundColor:theme.palette.background.dark,
    width:'50%',
    margin:'auto',
    boxShadow:'none',
    marginBottom:48,
    [theme.breakpoints.down('sm')]: {
      width:'100%',
    }
  },
  cardContent:{
    backgroundColor:'#E6F0F5',
  }
}));
const Testimonials: FC<TestimonialsProps> = ({ className, ...rest }) => {
  const classes = useStyles();
  const [testimony, setTestimony] = useState(Testimonies);
  
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
    <Fade right>
      <Container maxWidth="lg">
        <Box sx={{textAlign:"center", marginBottom: 5}}>
          <Typography variant="overline" color="gray" fontWeight={500}>Feedback from Users</Typography>
          <Typography variant="h1" fontWeight={700}>See what people are saying</Typography>
        </Box>

        <Box>
        <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        className="mySwiper"
      >
        {
          testimony.map((data)=>{
            return(
              <SwiperSlide key={data.id}>
                <Box key={data.id}>
                  <Card sx={{paddingTop:5}} className={classes.card}>
                    <CardContent className={classes.cardContent}>
                      <Box display="flex">
                        <Box 
                          mt={-6}
                          ml={2}
                          display="flex"
                          justifyContent="left"
                        >
                          <Avatar src={data.photoUrl} className={classes.avatar}/>
                        </Box>
                        <Box sx={{marginLeft:2, marginTop:0, color:'black'}}>
                          <Typography variant="h4">
                            {data.displayName}
                          </Typography>
                          {data.username!==null && 
                          <Typography variant="h6" color="dark" style={{opacity:'49%'}}> 
                            @{data.username}
                          </Typography>}
                        </Box>
                      </Box>
                      <Typography className={classes.testimony} variant="body1">
                        {data.testimony}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              </SwiperSlide>
            )
          })
        }
          </Swiper>
        </Box>
      </Container>
    </Fade>
    </div>
  );
};
Testimonials.propTypes = {
  className: PropTypes.string
};
export default Testimonials;
