import React from 'react';
import { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Avatar, Box, Container, Grid, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from 'src/theme';
import FeatureVideo from './FeatureVideo';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import { LayeredImage } from 'react-layered-image';
interface FeaturesProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // marginTop: 110,
    paddingTop: 16,
    paddingBottom: 16,
    position: 'relative',
    top: '-150px',
    [theme.breakpoints.down('lg')]: {
      paddingTop: 48,
      paddingBottom: 48
    },
    [theme.breakpoints.down('md')]: {
      top: 0
    }
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white
  },
  videoSection: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: 64,
    paddingTop: 64
  },
  dividerTop: {
    transform: 'rotate(180deg)',
    '& svg': {
      width: 'calc(160% + 1.3px)',
      height: '150px',
      fill: theme.palette.background.dark
    },
    [theme.breakpoints.down('md')]: {
      '& svg': {
        width: 'calc(166% + 1.3px)',
        height: '62px'
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        width: 'calc(162% + 1.3px)',
        height: '35px'
      }
    }
  },
  dividerBottom: {
    backgroundColor: theme.palette.background.dark,
    transform: 'rotate(180deg)',
    '& svg': {
      width: 'calc(160% + 1.3px)',
      height: '75px',
      transform: 'rotateY(180deg)',
      fill: '#ffffff'
    },
    [theme.breakpoints.down('md')]: {
      '& svg': {
        width: 'calc(166% + 1.3px)',
        height: '62px'
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        width: 'calc(162% + 1.3px)',
        height: '35px'
      }
    }
  }
}));

const Features: FC<FeaturesProps> = ({ className, ...rest }) => {
  const classes = useStyles();
  const qrLayers = [
    'static/home/features/bg-white.png',
    'static/home/features/qr/layer1.png',
    'static/home/features/qr/layer2.png',
    'static/home/features/qr/layer3.png',
    'static/home/features/qr/layer4.png'
  ];

  const transactionLayers = [
    'static/home/features/bg-white.png',
    'static/home/features/transaction/layer1.png',
    'static/home/features/transaction/layer2.png',
    'static/home/features/transaction/layer3.png',
    'static/home/features/transaction/layer4.png'
  ];

  const exportLayers = [
    'static/home/features/bg-white.png',
    'static/home/features/export/layer1.png',
    'static/home/features/export/layer2.png',
    'static/home/features/export/layer3.png',
    'static/home/features/export/layer4.png'
  ];

  const messageLayers = [
    'static/home/features/bg-white.png',
    'static/home/features/message/layer1.png',
    'static/home/features/message/layer2.png',
    'static/home/features/message/layer3.png',
    'static/home/features/message/layer4.png'
  ];

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div className={classes.dividerTop}>
        <svg
          data-name="Layer 1"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" />
        </svg>
      </div>
      <div className={classes.videoSection}>
        <Container maxWidth="lg">
          <Typography
            component="p"
            variant="overline"
            style={{ color: '#222222' }}
            align="center"
            fontWeight={500}
          >
            See how paygr works
          </Typography>
          <Typography
            variant="h1"
            fontWeight={700}
            align="center"
            style={{ color: '#222222' }}
          >
            Streamline your payment transactions.
          </Typography>
          <Box mt={8}>
            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                <Zoom>
                  <FeatureVideo />
                </Zoom>
              </Grid>
              <Grid item xs={12} md={6}>
                <Fade right>
                  <Stack rowGap={3}>
                    <Stack direction="row">
                      <Avatar className={classes.avatar}>1</Avatar>
                      <Box ml={2}>
                        <Typography
                          variant="h4"
                          fontWeight={700}
                          gutterBottom
                          color="black"
                        >
                          Add Payment Methods
                        </Typography>
                        <Typography variant="body1" color="#046473">
                          Expand your payment options and make it easy for
                          customers to pay you by adding new payment channels.
                        </Typography>
                      </Box>
                    </Stack>

                    <Stack direction="row">
                      <Avatar className={classes.avatar}>2</Avatar>
                      <Box ml={2}>
                        <Typography
                          variant="h4"
                          fontWeight={700}
                          gutterBottom
                          color="black"
                        >
                          Transact Payments
                        </Typography>
                        <Typography variant="body1" color="#046473">
                          Paygr provides a secure and user-friendly interface
                          for making and receiving payments. Whether you're
                          sending payments to suppliers, receiving payments from
                          customers, or making internal transfers.
                        </Typography>
                      </Box>
                    </Stack>

                    <Stack direction="row">
                      <Avatar className={classes.avatar}>3</Avatar>
                      <Box ml={2}>
                        <Typography
                          variant="h4"
                          fontWeight={700}
                          gutterBottom
                          color="black"
                        >
                          View Transactions
                        </Typography>
                        <Typography variant="body1" color="#046473">
                          View all of your transactions in one place with our
                          easy-to-use platform. Paygr allows you to monitor your
                          incoming and outgoing payments in real-time.
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                </Fade>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
      <div className={classes.dividerBottom}>
        <svg
          data-name="Layer 1"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" />
        </svg>
      </div>
      <Container maxWidth="lg">
        <Typography
          mt={{ xs: 6, lg: 14 }}
          component="p"
          variant="overline"
          style={{ color: '#222222' }}
          align="center"
          fontWeight={500}
        >
          Features
        </Typography>
        <Typography
          variant="h1"
          align="center"
          style={{ color: '#222222' }}
          fontWeight={700}
        >
          Enjoy these features for free.
        </Typography>
        <Box mt={16}>
          <Fade right>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} margin="auto">
                <Box display="flex" alignItems="center" justifyContent="center">
                  <LayeredImage
                    layers={qrLayers}
                    aspectRatio={4 / 4}
                    shadowOpacity={0}
                    borderRadius={0}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} margin="auto">
                <Box paddingX={{ xs: 0, lg: '50px' }} paddingY={'50px'}>
                  <Typography
                    variant="h2"
                    gutterBottom
                    color="textPrimary"
                    fontWeight={700}
                    fontSize={26}
                  >
                    Share all your payment channels with 1 qr code or link.
                  </Typography>
                  <Typography variant="body1" style={{ color: '#838383' }}>
                    In today's fast-paced world, it's important to have a quick
                    and convenient way to accept payments from customers. You
                    can easily share all your payment channels with just one QR
                    code or link. Whether you accept payments through E-Wallet,
                    Banks, or any other payment method, you can now streamline
                    the payment process and make it easier for your customers to
                    pay you.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Fade>

          <Fade left>
            <Grid container mt={{ xs: 6, lg: 16 }} spacing={3}>
              <Grid
                item
                xs={12}
                md={6}
                order={{ xs: 2, md: 2, lg: 2 }}
                margin="auto"
              >
                <Box paddingX={{ xs: 0, lg: '50px' }} paddingY={'50px'}>
                  <Typography
                    variant="h2"
                    gutterBottom
                    color="textPrimary"
                    fontWeight={700}
                    fontSize={26}
                  >
                    Monitor sent and received transactions.
                  </Typography>
                  <Typography variant="body1" style={{ color: '#838383' }}>
                    Keeping track of your transactions is an important part of
                    managing your finances. You can easily monitor all of your
                    sent and received transactions in one place. Whether you're
                    sending or receiving payments through a bank transfer, or
                    any other payment method, Paygr allows you to view all of
                    your transactions in real-time. With our solution, you can
                    stay on top of your transactions and take control of your
                    financial future.
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                margin="auto"
                order={{ xs: 1, md: 2, lg: 2 }}
              >
                <Box display="flex" alignItems="center" justifyContent="center">
                  <LayeredImage
                    layers={transactionLayers}
                    aspectRatio={4 / 4}
                    shadowOpacity={0}
                    borderRadius={0}
                  />
                </Box>
              </Grid>
            </Grid>
          </Fade>

          <Fade right>
            <Grid container mt={{ xs: 6, lg: 16 }} spacing={3}>
              <Grid item xs={12} md={6} margin="auto">
                <Box display="flex" alignItems="center" justifyContent="center">
                  <LayeredImage
                    layers={exportLayers}
                    aspectRatio={4 / 4}
                    shadowOpacity={0}
                    borderRadius={0}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} margin="auto">
                <Box paddingX={{ xs: 0, lg: '50px' }} paddingY={'50px'}>
                  <Typography
                    variant="h2"
                    gutterBottom
                    color="textPrimary"
                    fontWeight={700}
                    fontSize={26}
                  >
                    Generate and export reports within seconds.
                  </Typography>
                  <Typography variant="body1" style={{ color: '#838383' }}>
                    Generating reports is a time-consuming task, but it doesn't
                    have to be. With our solution, you can generate and export
                    reports within seconds and makes it easy to share your
                    reports with others or integrate them into other software
                    applications. With our solution, you can save time and
                    improve your productivity, allowing you to focus on other
                    important tasks.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Fade>

          <Fade left>
            <Grid container mt={{ xs: 6, lg: 16 }} spacing={3}>
              <Grid
                item
                xs={12}
                md={6}
                order={{ xs: 2, md: 1, lg: 1 }}
                margin="auto"
              >
                <Box paddingX={{ xs: 0, lg: '50px' }} paddingY={'50px'}>
                  <Typography
                    variant="h2"
                    gutterBottom
                    color="textPrimary"
                    fontWeight={700}
                    fontSize={26}
                  >
                    Encrypted Transaction and Chat
                  </Typography>
                  <Typography variant="body1" style={{ color: '#838383' }}>
                    Ensure the security of your financial transactions and
                    communications with Paygr. It offers encryption for both
                    your transactions and your chat conversations, so you can be
                    confident that your sensitive information is protected. You
                    can securely transfer funds, make payments, and chat with
                    others on Paygr, without worrying about prying eyes. Paygr
                    also provides real-time notifications for transactions and
                    chat messages, so you can stay up-to-date and never miss an
                    important update.
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                margin="auto"
                order={{ xs: 1, md: 2, lg: 2 }}
              >
                <Box display="flex" alignItems="center" justifyContent="center">
                  <LayeredImage
                    layers={messageLayers}
                    aspectRatio={4 / 4}
                    shadowOpacity={0}
                    borderRadius={0}
                  />
                </Box>
              </Grid>
            </Grid>
          </Fade>
        </Box>
      </Container>
    </div>
  );
};

Features.propTypes = {
  className: PropTypes.string
};

export default Features;
