export default [
  {
    id: 1,
    photoUrl: '/static/testimonials/user_01.jpg',
    testimony:
      'Paygr has made my life so much easier! I used to have to manage payments through multiple apps, but now I can do it all in one place. The real-time updates and notifications keep me in the loop every step of the way.',
    displayName: 'Vivian Torres',
    username: 'vivs'
  },

  {
    id: 2,
    photoUrl: '/static/testimonials/user_02.jpg',
    testimony:
      'Paygr helped me manage my online payment transactions more efficiently.',
    displayName: 'Jon Draniel Delos Santos',
    username: 'nlac_sc'
  },

  {
    id: 3,
    photoUrl: '/static/testimonials/user_03.jpg',
    testimony:
      "I love how Paygr offers so many payment options, including all the major e-wallets and banks. It's so convenient to have everything in one place.",
    displayName: 'Lito Maningo',
    username: 'litomaningo'
  },

  {
    id: 4,
    photoUrl: '/static/testimonials/user_04.jpg',
    testimony:
      'Paygr has simplified my business operations and helped me keep track of all my payments in one place. I highly recommend it to anyone looking for an efficient payment management system.',
    displayName: 'Mychel Guevarra',
    username: 'mychel'
  }
];
