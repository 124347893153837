import React from 'react';
import { Box, Typography } from '@mui/material';

export interface Article {
  title: string;
  content: any;
}

export const articles: Article[] = [
  {
    title: 'What is Paygr?',
    content: (
      <Box>
        <Typography>
          Paygr is a payment management app that streamlines peer-to-peer
          payment transactions. Paygr is designed specifically to address the
          problem of managing multiple payment methods and consolidating payment
          transaction details in one place. It offers a secure and user-friendly
          platform for seamless communication and payment arrangement between
          its users.
        </Typography>
      </Box>
    )
  },
  {
    title: 'What is Peer-To-Peer Transaction?',
    content: (
      <Box>
        <Typography>
          A peer-to-peer (P2P) payment transaction is a type of financial
          transaction where two parties exchange money directly with each other.
          With Paygr, you can easily send and receive payments directly from one
          person to another using peer-to-peer payment transactions. This means
          that you can quickly and conveniently transfer funds to friends,
          family, or anyone else without any intermediary or transaction fees.
        </Typography>
      </Box>
    )
  },
  {
    title: 'What is Paygr used for?',
    content: (
      <Box>
        <Typography>
          Paygr simplifies the payment process by enabling users to generate a
          single payment link or QR code that can be shared with others.
          Regardless of the payment method they prefer, the recipient can easily
          use the link or QR code to make the payment, eliminating the need for
          the sender and receiver to exchange payment information or worry about
          compatibility issues with different payment methods. With Paygr, users
          can easily facilitate payments without any complications, making the
          entire process seamless and stress-free.
        </Typography>
      </Box>
    )
  },
  {
    title: 'Why should I use Paygr?',
    content: (
      <Box>
        <Typography>
          • You should use Paygr because it provides a centralized platform for
          managing payments, making it easier for you to send and receive
          payments from multiple sources. With Paygr, you can create payment
          links and generate QR codes, making it simple and convenient for your
          customers to pay you. You can also track payment transactions in
          real-time and receive notifications to keep you informed about the
          status of your payments.
        </Typography>
        <Typography>
          • Paygr supports multiple payment methods, including e-wallets like
          GCash, Maya, ShopeePay, GrabPay, Alipay, and Palawan Pay, as well as
          bank transfers from major banks in the Philippines like BDO, BPI,
          Metrobank, Unionbank, and many more.
        </Typography>
        <Typography>
          • Paygr also has a chat feature that allows you to communicate with
          your customers without leaving the app, making it easier for you to
          address any concerns or questions they may have about their payments.
        </Typography>
        <Typography>
          • Using Paygr can help you streamline your payment processes and
          improve the overall payment experience for both you and your
          customers.
        </Typography>
      </Box>
    )
  },
  {
    title: 'What payment methods does Paygr supports?',
    content: (
      <Box>
        <Typography>
          Paygr supports multiple payment methods, including e-wallets such as
          GCash, Maya, ShopeePay, GrabPay, Alipay, and Palawan Pay, as well as
          bank transfers from major banks in the Philippines, such as BDO, BPI,
          Metrobank, Unionbank, and more. However, it's important to note that
          Paygr does not process the actual payment transactions. Instead, it
          provides a platform for managing payments and allows users to link
          their preferred payment method to facilitate peer-to-peer
          transactions.
        </Typography>
      </Box>
    )
  },
  {
    title: 'What is required to use Paygr?',
    content: (
      <Box>
        <Typography>
          To use Paygr, you need to have a verified e-wallet or bank account
          that you can add to the app. This will enable you to receive payments
          from your customers using various payment methods such as e-wallets or
          bank transfers. You also need to have a smartphone or mobile device
          running on either iOS or Android operating system and download the
          Paygr app from the App Store or Google Play Store. Additionally, you
          need a stable internet connection to access the app and make
          transactions.
        </Typography>
      </Box>
    )
  },
  {
    title: 'What do I need to get started with Paygr?',
    content: (
      <Box>
        <Typography>
          To get started with Paygr, you need to download the Paygr app from the
          App Store or Google Play Store, create an account using your Email,
          Google, Facebook, or Apple. Link your preferred payment methods such
          as e-wallets or bank accounts. Once you have linked your payment
          methods, you can start using Paygr to send and receive payments.
        </Typography>
      </Box>
    )
  },
  {
    title: 'Is Paygr free?',
    content: (
      <Box>
        <Typography>
          Yes, Paygr is free to use for both personal and business transactions.
          However, there may be fees charged by the payment methods or banks
          used for the transactions, which are outside of Paygr's control.
        </Typography>
      </Box>
    )
  },
  {
    title: 'Does Paygr have its own wallet system?',
    content: (
      <Box>
        <Typography>
          Paygr does not have a wallet feature. It facilitates peer-to-peer
          payment transactions by providing a centralized platform for managing
          payments and supporting multiple payment methods, including e-wallets
          and bank transfers. However, it does not have its own wallet feature
          for storing funds.
        </Typography>
      </Box>
    )
  },
  {
    title: 'Is Paygr connected to my online E-Wallets and Banks?',
    content: (
      <Box>
        <Typography>
          No, Paygr is not directly connected to your online e-wallets and
          banks. Instead, Paygr allows users to store their bank account details
          and supports multiple payment methods, including e-wallets such as
          GCash, Maya, ShopeePay, GrabPay, Alipay and Palawan Pay, and bank
          transfers from major banks in the Philippines like BDO, BPI,
          Metrobank, Union Bank, and more. This means that you can use Paygr to
          manage your payments across different payment platforms and banks,
          without having to switch between different apps or websites.
        </Typography>
      </Box>
    )
  },
  {
    title: 'Is my e-wallet and bank balance reflected in Paygr?',
    content: (
      <Box>
        <Typography>
          No, your e-wallet and bank balance are not reflected in Paygr. Paygr
          does not have access to your e-wallet or bank account balance.
          However, you can link your bank account or e-wallet to Paygr to
          facilitate peer-to-peer transactions outside of the app. Paygr
          provides a centralized platform for managing these transactions and
          allows you to track payment transactions in real-time, create payment
          links, generate QR codes for easy scanning, and view and manage all
          your payment transactions from different sources in one place.
        </Typography>
      </Box>
    )
  },
  {
    title: 'Does Paygr deduct money from my e-wallet and bank accounts?',
    content: (
      <Box>
        <Typography>
          No, Paygr does not deduct money from your e-wallet or bank accounts.
          It only facilitates payments between you and your customers by
          providing a platform to create payment links or generate QR codes.
          When a payment is made through Paygr, the money goes directly to your
          designated bank account or e-wallet account, depending on the payment
          method selected by the customer.
        </Typography>
      </Box>
    )
  },
  {
    title: 'How many payment options can I add in Paygr?',
    content: (
      <Box>
        <Typography>
          There is no limit to the number of payment options that you can add in
          Paygr. You can add multiple bank accounts and e-wallets to your Paygr
          account and use them to receive payments from your customers. This
          flexibility allows you to offer your customers more payment options
          and makes it easier for them to pay you.
        </Typography>
      </Box>
    )
  },
  {
    title: 'Is my data safe when I use Paygr?',
    content: (
      <Box>
        <Typography>
          Yes, Paygr takes data privacy and security seriously. The app uses
          encryption technology to protect all user information and
          transactions. Additionally, Paygr does not store any sensitive
          information, such as bank account or e-wallet login credentials. The
          app also adheres to industry-standard security protocols to ensure
          that user data is safe and secure.
        </Typography>
      </Box>
    )
  },

  {
    title: 'What is Paygr Shop?',
    content: (
    <Box>
    <Typography>
    Paygr Shop is a feature of Paygr that allows users to create product listings, share product links, and receive peer-to-peer payments for their products or services.
    </Typography>
    </Box>
    )
    },
    {
    title: 'How can I create a product listing on Paygr Shop?',
    content: (
    <Box>
    <Typography>
    To create a product listing on Paygr Shop, log in to your Paygr account and navigate to the Paygr Shop section. Click on the "Create Listing" button and provide the necessary details, including product description, pricing, and product images.
    </Typography>
    </Box>
    )
    },
    {
    title: 'What payment methods are available on Paygr Shop?',
    content: (
    <Box>
    <Typography>
    Paygr Shop supports various payment methods, including e-wallets and bank transfers. Buyers can choose their preferred payment option during the checkout process.
    </Typography>
    </Box>
    )
    },
    {
    title: 'How do I share my product listing with others?',
    content: (
    <Box>
    <Typography>
    Once you have created a product listing on Paygr Shop, you will receive a unique product link. You can share this link with your customers, friends, or on social media platforms to promote your products and encourage sales.
    </Typography>
    </Box>
    )
    },
    {
    title: 'What happens after a customer makes a purchase on Paygr Shop?',
    content: (
    <Box>
    <Typography>
    When a customer makes a purchase on Paygr Shop, you will receive a notification with the details of the transaction. It is then your responsibility to fulfill the order and deliver the product or service to the customer.
    </Typography>
    </Box>
    )
    },
    {
    title: 'How do I handle returns or refunds for purchases made on Paygr Shop?',
    content: (
    <Box>
    <Typography>
    As a seller on Paygr Shop, it is your responsibility to define your return or refund policy. You should communicate this policy clearly to your customers and handle any return or refund requests in accordance with your stated policy.
    </Typography>
    </Box>
    )
    },

  {
    title: 'What is Paygr Campaign?',
    content: (
    <Box>
    <Typography>
    Paygr Campaign is a feature of Paygr that enables users to create fundraising campaigns, set target amount goals, and receive peer-to-peer payments from supporters.
    </Typography>
    </Box>
    )
    },
    {
    title: 'How can I create a campaign on Paygr?',
    content: (
    <Box>
    <Typography>
    To create a campaign, log in to your Paygr account and navigate to the Paygr Campaign section. Click on the "Create Campaign" button and provide the necessary details, including the campaign title, description, target amount, and supporting images.
    </Typography>
    </Box>
    )
    },
    {
    title: 'Can I customize my campaign page?',
    content: (
    <Box>
    <Typography>
    Yes, you can customize your campaign page by adding compelling images, a detailed description, and updates to engage your supporters and convey your campaign's message effectively.
    </Typography>
    </Box>
    )
    },
    {
    title: 'How do I share my campaign with others?',
    content: (
    <Box>
    <Typography>
    Once you have created a campaign on Paygr, you will receive a unique campaign link. You can share this link with your friends, family, and social media followers to encourage them to support your cause and make donations.
    </Typography>
    </Box>
    )
    },
    {
    title: 'What payment methods are available for campaign donations?',
    content: (
    <Box>
    <Typography>
    Paygr Campaign supports various payment methods, including e-wallets and bank transfers. Donors can choose their preferred payment option during the donation process.
    </Typography>
    </Box>
    )
    },
    {
    title: 'Can I track the progress of my campaign?',
    content: (
    <Box>
    <Typography>
    Yes, Paygr provides a dashboard where you can track the progress of your campaign, including the total amount raised, the number of supporters, and any recent donations.
    </Typography>
    </Box>
    )
    },
    {
    title: 'How do I thank my campaign supporters?',
    content: (
    <Box>
    <Typography>
    It is recommended to express gratitude and appreciation to your campaign supporters. You can send personalized thank-you messages through the Paygr platform or use other means of communication to acknowledge their support.
    </Typography>
    </Box>
    )
    },


  {
    title: 'Does Paygr verify users?',
    content: (
      <Box>
        <Typography>
          No, Paygr does not verify users directly as it is not a financial
          institution. However, users are required to link their verified
          e-wallet and bank accounts to the app in order to send and receive
          payments. This means that users must have already gone through the
          verification process with their e-wallet or bank provider in order to
          use Paygr. Additionally, Paygr has security measures in place to help
          prevent fraud and unauthorized access to user accounts.
        </Typography>
      </Box>
    )
  },
  {
    title: 'What is the limitation of Paygr?',
    content: (
      <Box>
        <Typography>
          1. Limited payment methods: Although Paygr supports multiple payment
          methods, it does not support all payment methods available in the
          Philippines. For example, credit card payments are not supported.
        </Typography>
        <Typography>
          2. Peer-to-peer transactions only: Paygr is designed for peer-to-peer
          transactions, so it may not be suitable for businesses that need more
          advanced payment features such as invoicing or recurring payments.
        </Typography>
      </Box>
    )
  },
  {
    title: 'What if I need help on using Paygr?',
    content: (
      <Box>
        <Typography>
          If you need help using Paygr, you can reach out to their customer
          support team through the website chat feature. The team is available
          to assist you with any questions or issues you may have with the app.
          Additionally, Paygr has a comprehensive FAQ and Help Center section on
          their website that covers many common questions and issues. You can
          also find helpful tutorials and guides on their social media pages and
          other online resources.
        </Typography>
      </Box>
    )
  }
];
